import * as APIUtil from "../util/app_api_util";
import { receiveCurrentUser } from "./session_actions";
import { changeLoading } from "./loader_actions";
import { changeBanner } from "./banner_actions";
import { BannerStyle } from "../components/Banner";
import { ApplicationStage } from "../components/application/app_finish/Constants";

export const RECEIVE_APPLICATION = "RECEIVE_APPLICATION";
export const RECEIVE_EMAIL_CHECK = "RECEIVE_EMAIL_CHECK";
export const RECEIVE_APP_ERRORS = "RECEIVE_APP_ERRORS";
export const RECEIVE_BV_INFO = "RECEIVE_BV_INFO";
export const RECEIVE_PLAID_INCOME_INFO = "RECEIVE_PLAID_INCOME_INFO";
export const RECEIVE_APP_STAGE = "RECEIVE_APP_STAGE";
export const RECEIVE_APP_FINISH = "RECEIVE_APP_FINISH";

export const receiveApplication = application => ({
  type: RECEIVE_APPLICATION,
  application
});

export const receiveAppStage = stage => ({
  type: RECEIVE_APP_STAGE,
  stage
});

export const receiveEmailCheck = check => ({
  type: RECEIVE_EMAIL_CHECK,
  check
});

export const receiveAppErrors = errors => ({
  type: RECEIVE_APP_ERRORS,
  errors
});

export const receiveBVInfo = (info) => {
  return {
    type: RECEIVE_BV_INFO,
    info
  };
};

// leaving in remnants of plaid income integration, since we may use this again.  Determined it won't affect functionality
export const receivePlaidIncomeInfo = (incomeVerification) => {
  return {
    type: RECEIVE_PLAID_INCOME_INFO,
    incomeVerification
  };
}

export const receiveAppFinish = info => ({
  type: RECEIVE_APP_FINISH,
  info
});

export const submitPage1 = (esign1, esign2, time_displayed, user) => dispatch =>
  APIUtil.submitPage1(esign1, esign2, time_displayed, user).then(
    user => {
      dispatch(receiveCurrentUser(user));
      dispatch(receiveAppStage(ApplicationStage.CONTACT_INFO));
      dispatch(changeLoading(false));
    },
    errors => {
      dispatch(receiveAppErrors(errors));
      dispatch(changeLoading(false));
    }
  );

export const setApplication = application => dispatch =>
  dispatch(receiveApplication(application));

export const checkEmail = (email, referral) => dispatch =>
  APIUtil.checkEmail(email, referral).then(
    check => dispatch(receiveEmailCheck(check)),
    errors => dispatch(receiveAppErrors(errors))
  );

export const investigateSSN = user_id => dispatch =>
  APIUtil.investigateSSN(user_id);

export const submitPersonal = (address, user_id, user) => dispatch =>
  APIUtil.submitPersonal(address, user_id, user).then(
    () => {
      dispatch(receiveAppStage(ApplicationStage.SECURE_INFO));
      dispatch(changeLoading(false));
    },
    errors => {
      dispatch(receiveAppErrors(errors));
      dispatch(changeLoading(false));
    }
  );

export const submitSecure = (
  user_id,
  ssn,
  drivers_license,
  bank_account,
  browser,
  loan
) => dispatch =>
  APIUtil.submitSecure(
    user_id,
    ssn,
    drivers_license,
    bank_account,
    browser,
    loan
  ).then(
    resp => {
      // handle banner in all cases, regardless of whatever is about to come. this allows us to show banners even when we
      // are redirecting the user to a new step.
      if (resp.banner && resp.banner.message) {
        dispatch(
          changeBanner(
            resp.banner.message,
            resp.banner.style || BannerStyle.SUCCESS,
            resp.banner.duration || 5
          )
        );
      }

      if (resp.applicationCycle) {
        // MM: This is a bit hacky for now, but if we have an application cycle in the response, then the backend is attempting
        // to update the user. We could add some verification checks here to ensure that we are actually sending the user to
        // one of the desired app cycles, but for now, the only updates to app cycles that have been implemented are those
        // heading to acceptance.
        dispatch(receiveCurrentUser(resp));
        dispatch(receiveAppStage(ApplicationStage.APP_FINISH));
      } else if (resp.denied === true) {
        // The application has already been denied (tag is handled in a specific JSON flag). Send to denial
        dispatch(receiveAppStage(ApplicationStage.DENIED));
      } else {
        // Otherwise we've received BV info + should send to step 5.
        dispatch(receiveBVInfo(resp));
        dispatch(receiveAppStage(ApplicationStage.BANK_VERIFICATION));
      }

      // In all cases, the request has completed + we should stop showing the loading spinner.
      dispatch(changeLoading(false));
    },
    errors => {
      dispatch(receiveAppErrors(errors));
      dispatch(changeLoading(false));
    }
  );

export const submitPlaid = (token, loanId) => dispatch =>
  APIUtil.submitPlaid(token, loanId).then(
    () => {
      dispatch(receiveAppStage(ApplicationStage.APPROVAL_PENDING));
      dispatch(changeLoading(false));
    },
    errors => {
      dispatch(receiveAppErrors(errors));
      dispatch(changeLoading(false));
    }
  );

  // leaving in remnants of plaid income integration, since we may use this again.  Determined it won't affect functionality
export const postPlaidIncomeLink = (loanId) => dispatch => {
  APIUtil.postPlaidIncomeLink(loanId).then(
    () => {
      dispatch(changeLoading(false));
    },
    errors => {
      dispatch(changeLoading(false));
    }
  )
}


export const changeLoanAmount = (user_id, loan) => dispatch =>
  APIUtil.changeLoanAmount(user_id, loan).then(user =>
    dispatch(receiveCurrentUser(user), errors =>
      dispatch(receiveAppErrors(errors))
    )
  );

export const registerClick = (
  user_id,
  loan_id,
  click_type,
  comments
) => dispatch =>
  APIUtil.registerClick(user_id, loan_id, click_type, comments).then(
    null,
    errors => dispatch(receiveAppErrors)
  );

export const veritecOptIn = (user_id, time_displayed) => dispatch =>
  APIUtil.veritecOptIn(user_id, time_displayed).then(
    () => {
      dispatch(receiveAppStage(ApplicationStage.APPROVAL_PENDING));
      dispatch(changeLoading(false));
    },
    errors => {
      dispatch(receiveAppErrors(errors));
      dispatch(receiveAppStage(ApplicationStage.APPROVAL_PENDING));
      dispatch(changeLoading(false));
    }
  );

export const reapplyReminder = (loan_id, days) => dispatch =>
  APIUtil.reapplyReminder(loan_id, days);

export const changeLoanTerm = (user_id, action) => dispatch =>
  APIUtil.changeLoanTerm(user_id, action).then(
    info => {
      dispatch(receiveBVInfo(info));
      dispatch(receiveAppStage(ApplicationStage.BANK_VERIFICATION));
      dispatch(changeLoading(false));
    },
    errors => {
      dispatch(receiveAppErrors(errors));
      dispatch(changeLoading(false));
    }
  );

export const neededInfo = (
  user_id,
  bank_account,
  password,
  password_confirmation,
  phone,
  callback = () => {},
  error = () => {}
) => dispatch =>
  APIUtil.neededInfo(
    user_id,
    bank_account,
    password,
    password_confirmation,
    phone
  ).then(
    user => {
      if (user.banner && user.banner.message) {
        dispatch(changeBanner(user.banner.message, user.banner.style || BannerStyle.SUCCESS, user.banner.duration || 5))
      }

      dispatch(receiveBVInfo(user.BVInfo));
      dispatch(receiveCurrentUser(user));
      callback();
      dispatch(changeLoading(false));
    },
    errors => {
      dispatch(receiveAppErrors(errors));
      dispatch(changeLoading(false));
      error();
    }
  );

export const updatePhoneNumber = (user_id, phone_number) => dispatch =>
  APIUtil.updatePhoneNumber(user_id, phone_number).then(
    user => dispatch(receiveCurrentUser(user)),
    errors => {
      dispatch(receiveAppErrors(errors));
    }
  );

export const verifyPhone = (user_id, code, callback) => dispatch =>
  APIUtil.verifyPhone(user_id, code, callback).then(
    () => dispatch(changeLoading(false)),
    errors => dispatch(receiveAppErrors(errors))
  );

export const verifySignature = (user_id, signature, callback) => dispatch =>
  APIUtil.verifySignature(user_id, signature, callback).then(
    () => dispatch(changeLoading(false)),
    errors => dispatch(receiveAppErrors(errors))
  );

export const getTila = user_id => dispatch =>
  APIUtil.getTila(user_id).then(
    tila => {
      dispatch(receiveAppFinish(tila));
      dispatch(changeLoading(false));
    },
    errors => {
      dispatch(receiveAppErrors(errors));
      dispatch(changeLoading(false));
    }
  );

export const getCso = user_id => dispatch =>
  APIUtil.getCso(user_id).then(
    cso => {
      dispatch(receiveAppFinish(cso));
      dispatch(changeLoading(false));
    },
    errors => {
      dispatch(receiveAppErrors(errors));
      dispatch(changeLoading(false));
    }
  );

export const getLender = user_id => dispatch =>
  APIUtil.getLender(user_id).then(
    lender => {
      dispatch(receiveAppFinish(lender));
      dispatch(changeLoading(false));
    },
    errors => {
      dispatch(receiveAppErrors(errors));
      dispatch(changeLoading(false));
    }
  );

export const getFundingOptions = user_id => dispatch =>
  APIUtil.getFundingOptions(user_id).then(
    funding_options => {
      dispatch(receiveAppFinish(funding_options));
      dispatch(changeLoading(false));
    },
    errors => {
      dispatch(receiveAppErrors(errors));
      dispatch(changeLoading(false));
    }
  );

export const getPaymentOptions = (user_id, entered_debit) => dispatch =>
  APIUtil.getPaymentOptions(user_id, entered_debit).then(
    payment_options => {
      dispatch(receiveAppFinish(payment_options));
      dispatch(changeLoading(false));
    },
    errors => {
      dispatch(receiveAppErrors(errors));
      dispatch(changeLoading(false));
    }
  );

export const getSupplemental = user_id => dispatch =>
  APIUtil.getSupplemental(user_id).then(
    supplemental => {
      dispatch(receiveAppFinish(supplemental));
      dispatch(changeLoading(false));
    },
    errors => {
      dispatch(receiveAppErrors(errors));
      dispatch(changeLoading(false));
    }
  );

export const acceptLoan = (
  callback,
  user_id,
  loan_id,
  time_displayed,
  lender_signature,
  accept_ach,
  accept_rcc,
  accept_debit,
  cso_signature,
  eftav2,
  selected_payment_efta,
  funding_method
) => dispatch =>
  APIUtil.acceptLoan(
    callback,
    user_id,
    loan_id,
    time_displayed,
    lender_signature,
    accept_ach,
    accept_rcc,
    accept_debit,
    cso_signature,
    eftav2,
    selected_payment_efta,
    funding_method
  ).then(
    accepted => {
      dispatch(receiveAppFinish(accepted));
      dispatch(changeLoading(false));
    },
    errors => {
      dispatch(receiveAppErrors(errors));
      dispatch(changeLoading(false));
    }
  );

export const acceptModification = () => dispatch =>
  APIUtil.acceptModification().then(null, errors =>
    dispatch(receiveAppErrors(errors))
  );

export const easyApply = (user_id, loan) => dispatch =>
  APIUtil.easyApply(user_id, loan).then(
    user => {
      dispatch(receiveCurrentUser(user));
      dispatch(changeLoading(false));
    },
    errors => dispatch(receiveAppErrors(errors))
  );

export const addFundingCard = (
  user_id,
  debit_card,
  address,
  req_efta,
  efta_type,
  callback
) => dispatch =>
  APIUtil.addFundingCard(user_id, debit_card, address, req_efta, efta_type).then(
    success => {
      // eslint-disable-next-line no-unused-expressions
      callback ? callback(null, success) : null;
    },
    errors => {
      // eslint-disable-next-line no-unused-expressions
      callback ? callback(errors.errors, null) : null;
      dispatch(receiveAppErrors(errors))
    }
  );

export const preapprove = (success, error) => dispatch =>
  APIUtil.preapprove().then(
    user => {
      dispatch(receiveCurrentUser(user));
      dispatch(changeLoading(false));
      success();
    },
    errors => {
      dispatch(changeLoading(false));
      error();
      dispatch(receiveAppErrors(errors));
    }
  );

export const instantRepeat = success => dispatch =>
  APIUtil.instantRepeat().then(
    user => {
      dispatch(receiveCurrentUser(user));
      dispatch(changeLoading(false));
      success();
    },
    errors => {
      dispatch(changeLoading(false));
      dispatch(receiveAppErrors(errors));
    }
  );

export const prequalOrPlaid = accept => dispatch =>
  APIUtil.prequalOrPlaid(accept).then(
    user => {
      dispatch(receiveCurrentUser(user));
      dispatch(changeLoading(false));
    },
    errors => {
      dispatch(changeLoading(false));
      dispatch(receiveAppErrors(errors));
    }
  );