import { merge } from "lodash";
import {
  RECEIVE_CURRENT_USER,
  RECEIVE_NEXT_CUT_OFF
} from "../actions/session_actions";
import { RECEIVE_PAYMENT_SCHEDULE } from "../actions/user_actions";
import { RECEIVE_SESSION_ERRORS } from "../actions/error_actions";
import { createCookie, eraseCookie } from "../helpers/cookie_helper";
import { recordUtmToDatabase } from "../components/shared/storageAvailable";

// const sessionReducer = (oldState = { currentUser: null }, action) => {
//   Object.freeze(oldState);
//   switch (action.type) {
//     case RECEIVE_CURRENT_USER:
//       if (action.currentUser) {
//         window.$.ajaxSetup({ data: { token: action.currentUser.apiToken } });
//         createCookie("email", action.currentUser.email, 2);
//         createCookie("apiToken", action.currentUser.apiToken, 2);
//       } else {
//         eraseCookie("email");
//         eraseCookie("apiToken");
//       }
//       return merge({}, { currentUser: action.currentUser });
//     case RECEIVE_SESSION_ERRORS:
//       return merge({}, { currentUser: null });
//     default:
//       return oldState;
//   }
// };

const sessionReducer = (oldState = { id: null, currentUser: null }, action) => {
  Object.freeze(oldState);
  switch (action.type) {
    case RECEIVE_CURRENT_USER:
      if (action.currentUser) {
        recordUtmToDatabase(action.currentUser.id);
        window.apiToken = action.currentUser.api_token;
        window.$.ajaxSetup({ data: { token: window.apiToken } });
        createCookie("email", action.currentUser.email, 2);
        createCookie("apiToken", action.currentUser.api_token, 2);
      } else {
        eraseCookie("email");
        eraseCookie("apiToken");
      }
      return merge({}, oldState, { currentUser: action.currentUser });
    case RECEIVE_PAYMENT_SCHEDULE:
      return Object.assign({}, oldState, { currentUser: action.user });
    case RECEIVE_SESSION_ERRORS:
      return merge({}, oldState, { currentUser: null });
    case RECEIVE_NEXT_CUT_OFF:
      return merge({}, oldState, {
        paymentNextCutOff: action.info.next_cut_off
      });
    default:
      return oldState;
  }
};

export default sessionReducer;
