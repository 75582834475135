import { merge } from "lodash";
import {
  RECEIVE_APPLICATION,
  RECEIVE_APP_STAGE,
  RECEIVE_EMAIL_CHECK,
  RECEIVE_BV_INFO,
  RECEIVE_PLAID_INCOME_INFO
} from "../actions/application_actions";

import { RECEIVE_CURRENT_USER } from "../actions/session_actions";

const defaultState = {
  details: { apr: 190, amount: 300, length: 4 },
  emailCheck: "",
  stage: 1,
  info: {},
  incomeVerification: {}
};

const applicationReducer = (oldState = defaultState, action) => {
  Object.freeze(oldState);
  switch (action.type) {
    case RECEIVE_APPLICATION:
      return merge({}, oldState, { details: action.application });
    case RECEIVE_CURRENT_USER:
      let stage;
      if (action.currentUser) {
        switch (action.currentUser.activeLoanCycle) {
          // User has no account or no active loan
          case "ab00":
          case "ab01":
          case "ab02":
            break;
          // User has active loan, not eligible for refi
          case "ab03":
          case "ab04":
          case "ab05":
          case "ab08":
            stage = 8;
            return merge({}, oldState, { stage });
          // User has active loan, refi eligible, so enter into applicationCycle
          case "ab06":
          case "ab07":
            break;
          default:
            break;
        }
        switch (action.currentUser.applicationCycle) {
          // no email associated
          case "aa00":
            stage = 1;
            break;
          // user has account, no info
          // user has account with info, but no recent app, so let them edit
          case "aa01":
          case "aa17":
          case "aa21":
          case "aa03":
          // user has a discharged bankruptcy and should be able to submit an application.
          case "_a00":
            stage = 3;
            break;
          // user has account with info, no recent app
          case "aa02":
          case "aa28":
            stage = 4;
            break;
          // user hasn't completed bank verification
          case "aa12":
          case "aa13":
          case "aa14":
          case "aa15":
          case "aa16":
          case "aa23":
            stage = 5;
            break;
          // user has pending application
          case "aa11":
          case "aa20":
            stage = 6;
            break;
          // user application was denied and it hasn't been 7 days
          case "aa07":
          case "aa08":
          case "aa09":
          case "aa10":
          case "aa19":
          case "aa24":
            stage = -7;
            break;
          // user has approved application, not accepted yet
          case "aa04":
          case "aa05":
          case "aa06":
          case "aa22":
          case "aa29":
          case "aa30":
          case "aa_10": // This is Income verified but not accepted yet
            stage = 7;
            break;
          // user has active loan, not refi eligible
          case "aa18":
            stage = 8;
            break;
          // user has an approved application, needs income verification
          case "aa_7":
            stage = 9;
            break;
          case "aa_8":
          case "aa_9":
            stage = 10;
            break;
          default:
            stage = 1;
            break;
        }
        // MD: Adding in the user ID, so it's available for Plaid Income Link
        // console.log('User app cycle: ' + `${action.currentUser.applicationCycle}`);
        return merge({}, oldState, { stage, user_id: action.currentUser.id });
      } else {
        return defaultState;
      }
    case RECEIVE_APP_STAGE:
      return merge({}, oldState, { stage: action.stage });
    case RECEIVE_EMAIL_CHECK:
      return merge({}, oldState, {
        emailCheck: action.check.email,
        referrer: action.check.name
      });
    case RECEIVE_BV_INFO:
      return merge({}, oldState, {
        info: {
          ...action.info,
          ibv: action.info.ibv === null ? oldState.info.ibv : action.info.ibv
        }
      });
    case RECEIVE_PLAID_INCOME_INFO:
      return merge({}, oldState, {
        incomeVerification: {
          ...action.incomeVerification
        }
      })
    default:
      return oldState;
  }
};

export default applicationReducer;
